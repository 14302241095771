import React, { useEffect, useMemo, useRef, useState } from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import HomeIcon from "@material-ui/icons/Home";
import ReloadIcon from "@material-ui/icons/Refresh";
import { Link } from "gatsby";

import { getAvailablePages } from "../utils";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const EditorPages = ({ data }) => {
    const theme = useMemo(() =>
        createTheme({
            palette: {
                type: "dark"
            }
        })
    );

    const useStyles = makeStyles(theme => ({
        formContainer: {
            display: "flex",
            flexDirection: "row",
            flexBasis: "auto",
            justifyContent: "center"
        },
        formControl: {
            position: "absolute",
            margin: theme.spacing(1),
            minWidth: 200
        },
        menuGroup: {
            padding: "5px 15px 0 15px",
            lineHeight: "16px",
            fontWeight: 900
        },
        menuItem: {
            padding: "5px 15px 5px 30px"
        },
        contentFrame: {
            backgroundColor: "black",
            color: "white",
            width: "100%",
            border: "none",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            flexBasis: "auto",
            alignItems: "center",
            justifyContent: "center",
            "& div": {
                flexDirection: "row",
                padding: 15
            }
        }
    }));
    const classes = useStyles();

    // get the folders/pages in an object from the result of the fs query
    const availablePages = getAvailablePages(data.allFile.edges);

    const [loaded, setLoaded] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [selectedPage, setSelectedPage] = useState("");
    const [reloadPage, setReloadPage] = useState(false);
    const loadedPage = useRef();
    const intervalRef = useRef();

    /**
     * We want to have a handy way to reload the current page without having to reselect it from the dropdown every time
     * We need to temp load a null page and then set it back again in order to force a reload/render - otherwise React will just ignore us
     */
    useEffect(() => {
        if (reloadPage) {
            loadedPage.current = { selectedFolder, selectedPage };
            setSelectedFolder("");
            setSelectedPage("");
            const id = setInterval(() => {
                setSelectedFolder(loadedPage.current.selectedFolder);
                setSelectedPage(loadedPage.current.selectedPage);
                setReloadPage(false);
            });
            intervalRef.current = id;
            return () => {
                clearInterval(intervalRef.current);
            };
        }
    }, [reloadPage, setReloadPage]);

    const handleMenuItemClick = (folder, page) => {
        setSelectedFolder(folder);
        setSelectedPage(page);
    };

    const doPageReload = () => {
        setReloadPage(true);
    };

    useEffect(() => {
        setLoaded(true);
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={classes.formContainer}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="page-select-label">
                        {(!selectedPage && (
                            <div>Select a page to load</div>
                        )) || (
                            <div>
                                {selectedFolder.name}/{selectedPage}
                            </div>
                        )}
                    </InputLabel>
                    <Select labelId="page-select-label" id="page-select">
                        {availablePages.map((folder, folderIndex) => (
                            <div key={folderIndex}>
                                <ListSubheader className={classes.menuGroup}>
                                    {folder.name}
                                </ListSubheader>
                                <hr />
                                {folder.pages.map((page, pageIndex) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        value={page}
                                        key={pageIndex}
                                        onClick={() =>
                                            handleMenuItemClick(folder, page)
                                        }
                                    >
                                        {page}
                                    </MenuItem>
                                ))}
                            </div>
                        ))}
                    </Select>
                    <div>
                        <Link to="/">
                            <HomeIcon />
                        </Link>
                        <ReloadIcon onClick={() => doPageReload()} />
                    </div>
                </FormControl>
            </div>
            <div className={classes.contentFrame}>
                {!loaded && <div>Loading...</div>}
                {(!selectedPage && (
                    <div>Please choose a page to load from the menu</div>
                )) || (
                    <iframe
                        onLoad={() => setLoaded(true)}
                        src={`/editor/${selectedFolder.name}/${selectedPage}`}
                        className={classes.contentFrame}
                        title={`${selectedFolder.name}/${selectedPage}`}
                        width="100%"
                    />
                )}
            </div>
        </ThemeProvider>
    );
};

export const query = graphql`
    {
        allFile(
            filter: {
                sourceInstanceName: { eq: "editor-pages" }
                extension: { eq: "html" }
            }
        ) {
            edges {
                node {
                    relativePath
                }
            }
        }
    }
`;

export default EditorPages;
