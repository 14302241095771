/**
 * This utility method takes the data.allFile.edges object retrieved from a graphQL query on the gatsby-source-filesystem plugin
 *
 * The following shows the gatsby-config.js plugin setup for getting the static/editor pages made available via graphQL
 *
 * @example
 * {
 *      resolve: `gatsby-source-filesystem`,
 *      options: {
 *          name: `editor-pages`,
 *          path: `${__dirname}/static/editor`
 *      }
 * }
 *
 * This can then be queried and filtered like this :
 *
 * @example
 * export const query = graphql`
 *    {
 *       allFile(
 *            filter: {
 *                sourceInstanceName: { eq: "editor-pages" }
 *                extension: { eq: "html" }
 *            }
 *        ) {
 *           edges {
 *                node {
 *                    relativePath
 *                }
 *            }
 *        }
 *    }
 *`;
 *
 * @param {*} allFileEdges This should be the GraphQL result from an FS query to get relative paths to all html within a folder - ie : data.allFile.edges
 * @returns an array of objects in the format [{name: 'myFolder1', pages: ['page1.html', 'page2.html]}, {name: 'myFolder2', pages: ['page1.html', 'page2.html]}]
 */

const getAvailablePages = allFileEdges => {
    return allFileEdges.reduce((folders, node) => {
        const { relativePath } = node.node;
        const folderName = relativePath.substr(0, relativePath.indexOf("/"));
        const pageName = relativePath.substr(relativePath.indexOf("/") + 1);
        if (folders) {
            const existingFolder = folders.find(
                folders => folders.name === folderName
            );
            existingFolder
                ? existingFolder.pages.push(pageName)
                : folders.push({
                      name: folderName,
                      pages: [pageName]
                  });
        }
        return folders;
    }, []);
};

export { getAvailablePages };
